import React from 'react';
import './style/SectionWhoWeAre.css';
import SectionLeft from './SectionLeft';
import logo_icon from '../resource/logo-icon.png';
import arrow_up from '../resource/arrow-top.png';
import arrow_right from '../resource/arrow-right.png';
import arrow_left from '../resource/arrow-left.png';
import ItemValue from './ItemValue';
import divider_bg from '../resource/divider-bg.png';

const SectionWhoWeAre = () => (
    <div id="section-who-we-are" className="row items-stretch content-between">
      <img style={{position: 'absolute', width: '100%', top: 0, left: 0, right: 0}} src={divider_bg} alt="error"/>
      <SectionLeft textSize={216} text="Quem Somos" />
      <div className="flex-1 column items-stretch section-who-we-are-content">
        <div className="section-who-we-are-content-title">
          <div className="flex-1 section-who-we-are-first-text">
            Somos o <strong>Primeiro</strong><br />escritório de advocacia de <strong>Itapetininga e região</strong> especializado em<br/>
            <strong>Direito das Mulheres e Desigualdade de Gênero</strong>
          </div>
          <div className="flex-1 column section-who-we-are-second-text">
            <span className="section-who-we-are-second-text-first">
              <strong>
                Com rígidos padrões éticos atuamos com presteza e agilidade na defesa dos interesses dos seus clientes.
              </strong>
            </span>
            <span className="section-who-we-are-second-text-first">
              <strong>
                Adotamos uma filosofia preventiva, aplicando soluções jurídicas inovadoras com transparência, ética e respeito.
              </strong>
            </span>
            <span>
              O escritório mantêm constante aprimoramento em seus aspectos organizacionais. Procuramos nos reinventar constantemente oferecendo uma advogacia diferenciada, técnica, combativa e crítica.
            </span>
          </div>
        </div>
        <div className="flex-1 column items-stretch section-who-we-are-content-2">
          <div className="row items-center">
            <span className="section-who-we-are-title">Missão</span>
            <div className="section-who-we-are-title-border" />
          </div>
          <div className="flex-1 column items-stretch">
            <div className="section-who-we-are-text-2-container">
              <div className="flex-1 section-who-we-are-text-2">
                <span>
                  Atender nossos clientes em todas as áreas do direito, no alcance de seus objetivos, com criatividade, segurança e eficiência.
                </span>
              </div>
              <div className="flex-1 section-who-we-are-text-2">
                <span>
                  Aprimorar e compartilhar constantemente o conhecimento. Praticar e transmitir valores éticos.
                </span>
              </div>
            </div>
            <div className="section-who-we-are-vision-values">
              <div className="column section-who-we-are-side">
                <span className="section-who-we-are-title">Visão</span>
                <span className="section-who-we-are-text-2">
                  Acreditamos no aperfeiçoamento da sociedade pelo Direito.
                </span>
                <span className="section-who-we-are-text-2">
                  E numa advocacia compromissada com a ética, responsabilidade, excelência das soluções legais, satisfação de nossos clientes.
                </span>
              </div>
              <div className="items-stretch content-center who-we-are-circle">
                <div className="items-center content-center who-we-are-circle-arrow who-we-are-circle-arrow-top">
                  <img src={arrow_up} className="who-we-are-arrow-top" alt="error"/>
                </div>
                <div className="items-center content-center who-we-are-circle-arrow who-we-are-circle-arrow-right">
                  <img src={arrow_right} className="who-we-are-arrow" alt="error"/>
                </div>
                <div className="items-center content-center who-we-are-circle-arrow who-we-are-circle-arrow-left">
                  <img src={arrow_left} className="who-we-are-arrow" alt="error"/>
                </div>
                <div className="flex-1 items-stretch content-center who-we-are-circle-content who-we-are-circle-content-1">
                  <div className="flex-1 items-stretch content-center who-we-are-circle-content who-we-are-circle-content-2">
                    <div className="flex-1 items-stretch content-center who-we-are-circle-content who-we-are-circle-content-3">
                      <div className="flex-1 items-stretch content-center who-we-are-circle-content who-we-are-circle-content-4">
                        <img src={logo_icon} className="who-we-are-logo" alt="error" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column section-who-we-are-side">
                <span className="section-who-we-are-title">Valores</span>
                <ItemValue text="Ética" />
                <ItemValue text="Lealdade" />
                <ItemValue text="Diligência" />
                <ItemValue text="Confidencialidade" />
                <ItemValue text="Respeito" />
                <ItemValue text="Igualdade" />
                <ItemValue text="Diversidade" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

export default SectionWhoWeAre;
