import React from 'react';
import './style/ServiceItem.css';
import {Link} from 'react-router-dom';

const ServiceItem = ({noBorder = false, image, text, subText}) => (
    <div className={`flex-1 row content-between items-end service-item-container ${noBorder ? 'service-item-container-no-border' : 'service-item-container-border'}`}>
      <div className="row items-center">
        <div className="items-center content-center service-item-img-container">
          <img src={image} alt='error' />
        </div>
        <div className="service-item-text-container">
          <span className="service-item-text">{text}</span>
          <span className="service-item-text"><strong>{subText}</strong></span>
        </div>
      </div>
      <a className='service-item-link' href={'/nossos-servicos'}>
        Saiba mais
      </a>
    </div>
)

export default ServiceItem;
