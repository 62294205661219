import React from 'react';
import SectionBanner from '../component/SectionBanner';
import SectionInitialDivider from '../component/SectionInitialDivider';
import SectionInitial from '../component/SectionInitial';
import SectionWhoWeAre from '../component/SectionWhoWeAre';
import SectionOurServices from '../component/SectionOurServices';
import SectionWhatWeOffer from '../component/SectionWhatWeOffer';
import SectionContact from '../component/SectionContact';
import divider_bg from '../resource/divider-bg.png';

const Home = () => (
    <>
      <SectionBanner />
      <SectionInitialDivider />
      <img className="divider-bg" src={divider_bg} alt="error"/>
      <SectionInitial />
      <SectionWhoWeAre />
      <SectionOurServices />
      <img className="divider-bg" src={divider_bg} alt="error"/>
      <SectionWhatWeOffer />
      <SectionContact />
    </>
);

export default Home;
