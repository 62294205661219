import React from 'react';
import './style/Footer.css';
import divider_bg from '../resource/divider-bg.png';

const Footer = () => (
    <footer className="row items-center content-center">
      <img style={{position: 'absolute', width: '100%', top: 0, left: 0, right: 0}} src={divider_bg} alt="error"/>
      <p>{new Date().getFullYear()} © Thalita Lucarelli. Todos os Direitos Reservados.</p>
    </footer>
);

export default Footer;
