import React from 'react';
import './style/SectionBanner.css';
import banner_left_bg from '../../resource/banner-left-bg.png';
import banner_right_bg from '../../resource/banner-right-bg.png';

const SectionBanner = () => (
    <div id="page-our-services-banner" className="column items-center content-center">
      <img src={banner_left_bg} className="banner-left-bg" alt="error" />
      <p className="page-our-services-banner-title">Nossos</p>
      <p className="page-our-services-banner-title"><strong>Serviços</strong></p>
      <img src={banner_right_bg} className="banner-right-bg" alt="error" />
    </div>
);

export default SectionBanner;
