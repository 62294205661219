import React from 'react';
import './style/DefaultButton.css'
import {Link} from 'react-router-dom';

const DefaultButton = ({text, onClick, isContact=false}) =>
    !isContact ? (
        <a className='items-center content-center default-btn' href={'/nossos-servicos'}>
            {text}
        </a>
    ) : (
        <div className='items-center content-center default-btn' onClick={onClick}>
            {text}
        </div>
    );

export default DefaultButton;
