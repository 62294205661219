import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Header from './component/Header';
import Home from './screen/Home';
import Footer from './component/Footer';
import OurServices from './screen/OurServices';
import divider_bg from './resource/divider-bg.png';

const App = () => (
    <Router>
      <div className="app-content">
        <Header/>
        <img style={{position: 'absolute', width: '100%', top: 75, left: 0, right: 0, zIndex: 2}} src={divider_bg} alt="error"/>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/nossos-servicos">
            <OurServices/>
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
);

export default App;
