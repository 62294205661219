import React from 'react';
import SectionBanner from '../component/OurServices/SectionBanner';
import SectionAssistJurid from '../component/OurServices/SectionAssistJurid';
import SectionServiceItems from '../component/OurServices/SectionServiceItems';
import SectionContact from '../component/SectionContact';
import SectionOtherServices
  from '../component/OurServices/SectionOtherServices';

const OurServices = () => (
    <>
      <SectionBanner />
      <SectionAssistJurid />
      <SectionServiceItems />
      <SectionOtherServices />
      <SectionContact />
    </>
);

export default OurServices;
