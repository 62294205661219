import React from 'react';
import './style/LabelInput.css';
import TextInputMask from 'react-masked-text';

const LabelInput = ({label, mask = '', inputProps = {}, inputMargin = false}) => (
    <div className={`flex-1 column items-stretch label-input-container ${inputMargin && 'label-input-container-margin'}`}>
      <p className="input-label">{label}</p>
      {mask ? (
          <TextInputMask
              className="input-text"
              kind={'cel-phone'}
              {...inputProps}
          />
      ) : (
          <input {...inputProps} className="input-text"/>
      )}
    </div>
);

export default LabelInput;
