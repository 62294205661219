import React from 'react';
import './style/SectionInitial.css';
import SectionLeft from './SectionLeft';
import ButtonWhatsapp from './ButtonWhatsapp';
import aperto_mao from '../resource/aperto-mao.png';
import dual_quote from '../resource/dual-quote.png';
import initial_divisor_bg from '../resource/initial-divisor-bg.png';
import {openWhatsApp, scrollTo} from '../util/Action';

const SectionInitial = () => (
    <div id="section-initial" className="row items-stretch content-between">
        <SectionLeft text="Início" />
        <div className="flex-1 column">
            <div className="section-initial-content">
                <div className="column items-stretch section-initial-content-left">
                    <span className="section-initial-title">
                        Temos nossa ideologia baseada na prestação macro de <strong>serviços advocatícios...</strong>
                    </span>
                        <span className="section-initial-text">
                        ...com máxima prioridade, olhar crítico para todas as questões apresentadas, com o propósito de construir um relacionamento próximo, empenhando todos os esforços para que cada um de nossos clientes tenham um atendimento personalisado.
                    </span>
                        <span className="section-initial-text">
                        Temos como premissa ser a extensão do departamento jurídico de nossos clientes, conceito que aproxima e humaniza a relação advogado/cliente.
                    </span>
                </div>
                <div className="flex-1 items-center content-center">
                    <div className="section-initial-right-content">
                        <img src={aperto_mao} className="img-aperto-mao" alt="error" />
                        <div className="section-initial-right-content-bg" />
                        <div className="section-initial-right-text-container">
                            <div className="row content-center items-center quote-icon-container">
                                <img src={dual_quote} className="img-quote" alt="error" />
                            </div>
                            <span className="section-initial-right-text">
                                Somos especialistas em resolver o seu problema!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <ButtonWhatsapp
                onClick={() => openWhatsApp('Olá vim pelo site. Tenho uma questão jurídica e gostaria de agendar uma consulta, qual seria a melhor data?')}
                text="Agende sua consulta"
            />
        </div>
        <img src={initial_divisor_bg} className="img-initial-divisor-bg" alt="error" />
    </div>
);

export default SectionInitial;
