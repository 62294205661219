import React from 'react';
import './style/SectionOtherServices.css';
import consult_juridica_icon from '../../resource/consult-juridica-icon.png';
import company_icon from '../../resource/company-icon.png';
import divider_bg from '../../resource/divider-bg.png';
import OtherService from './OtherService';

const SectionOtherServices = () => (
    <div id="page-our-services-other" className="column items-stretch content-center">
      <img style={{position: 'absolute', width: '100%', top: 0, left: 0, right: 0, zIndex: 2}} src={divider_bg} alt="error"/>
      <OtherService
          image={consult_juridica_icon}
          title="Consultoria"
          subTitle="Jurídica"
          text="Oferecemos consultoria jurídica para a promoção de ambientes inclusivos compromissados com a diversidade. Auxiliamos na solução de litígios e controvérsias que envolvem desigualdade de gênero e assuntos ligados à mulher e à população LGBT+."
      />
      <div style={{height: 80}} />
      <OtherService
          image={company_icon}
          subTitle="Empresas"
          text="Atualmente destacam-se no mercado às empresas que promovem a igualdade entre os gêneros. Empresas inclusivas são mais competitivas e atraem mais profissionais qualificados no mercado moderno. Além disso, assessoramos empresas a solucionar problemas jurídicos, de maneira ética e comprometida com os direitos humanos."
      />
    </div>
);

export default SectionOtherServices;
