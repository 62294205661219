import React from 'react';
import './style/SectionLeft.css';

const SectionLeft = ({text, textSize = 90, color = '#DDDCDF'}) => (
    <div className='column items-center section-left'>
      <div className='section-left-text-container' style={{height: textSize}}>
        <span className='section-left-text' style={{width: textSize, color}}>{text}</span>
      </div>
      <div className="section-left-border" style={{backgroundColor: color}} />
    </div>
);

export default SectionLeft;
