import React, {useState} from 'react';
import './style/SectionContact.css';
import SectionLeft from './SectionLeft';
import CircleIconText from './CircleIconText';
import CircleIcon from './CircleIcon';
import LabelInput from './LabelInput';
import LabelTextArea from './LabelTextarea';
import divider_bg from '../resource/divider-bg.png';
import contact_left_bg from '../resource/contact-left-bg.png';
import whatsapp_icon from '../resource/whatsapp-icon-white.png';
import facebook_icon from '../resource/facebook-icon.png';
import instagram_icon from '../resource/instagram-icon.png';
import mail_icon from '../resource/main-icon.png';
import contact_right_bottom_bg from '../resource/contact-right-bottom-bg.png';
import {sendMail} from '../store/action/MailAction';
import {openFacebook, openInstagram, openWhatsApp} from '../util/Action';

const SectionContact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const _onNameChange = ({currentTarget: {value}}) => setName(value);
  const _onEmailChange = ({currentTarget: {value}}) => setEmail(value);
  const _onPhoneChange = (value) => setPhone(value);
  const _onMessageChange = ({currentTarget: {value}}) => setMessage(value);

  const _mailSend = () => {
    if (name === '' || email === '' || phone === '' || message === '') {
      alert("Por favor preencha todos os campos obrigatórios.");
      return;
    }

    const phoneNumber = phone.replaceAll("(", "")
                              .replaceAll(")", "")
                              .replaceAll(" ", "")
                              .replaceAll("-", "");

    if (phoneNumber.length < 11) {
      alert("Por favor preencha o telefone corretamente.");
      return;
    }

    setLoading(true);
    sendMail({name, email, phone, message})
        .then(_ => alert('Obrigado logo entraremos em contato!'))
        .finally(() => {
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setEmailSent(true);
          setTimeout(() => {
            setEmailSent(false);
            setLoading(false);
          }, 1000);
        }).catch(({response}) => {
          console.warn('_mailSend - error: ', response);
          if (response.status === 400) {
            const {message, invalidFields} = response.data;

            alert(`${message}\n${invalidFields}`);
            return;
          }
          alert('Erro ao enviar E-mail, tente novamente mais tarde.');
          console.warn('_mailSend - error: ', );
        });
  }

  return (
      <div id="section-contact">
        <img className="contact-divider-bg" src={divider_bg} alt="error" />
        <div className="flex-1 row items-stretch section-contact-left">
          <SectionLeft color="#A9A7AF" textSize={210} text="Contato" />
          <div className="flex-1 column items-stretch section-contact-left-content">
            <p className="section-contact-left-title">
              Entre em contato!
            </p>
            <div className="flex-1 column content-between section-contact-left-icon-content">
              <div>
                <CircleIconText
                    title="Telefone/WhatsApp"
                    text="(15) 99182-7527"
                    image={whatsapp_icon}
                    style={{backgroundColor: "#24D06C"}}
                    iconSize={18}
                    onClick={
                      () => openWhatsApp('Olá vim pelo site. Tenho uma questão jurídica e gostaria de agendar uma consulta, qual seria a melhor data?')
                    }
                    isHover={true}
                />
                <CircleIconText
                    title="E-mail"
                    text="tlucarelli.adv@gmail.com"
                    image={mail_icon}
                    iconSize={18}
                />
              </div>
              <div className="row fb-insta-container">
                <CircleIcon
                    image={facebook_icon}
                    iconSize={10}
                    size={45}
                    onClick={openFacebook}
                    isHover={true}
                />
                <div className="section-contact-left-icon-divisor" />
                <CircleIcon
                    image={instagram_icon}
                    iconSize={15}
                    size={45}
                    onClick={openInstagram}
                    isHover={true}
                />
              </div>
            </div>
          </div>
          <img className="contact-left-bg" src={contact_left_bg} alt="error" />
        </div>
        <div className="flex-1 column items-stretch">
          <div className="flex-1 column items-stretch section-contact-right-content">
            <p className="section-contact-right-title">Agende uma consulta</p>
            <div className="row">
              <LabelInput
                  label="Nome *"
                  inputProps={{
                    value: name,
                    onChange: _onNameChange
                  }}
              />
            </div>
            <div className="row">
              <LabelInput
                  label="Telefone *"
                  mask='phone'
                  inputProps={{
                    value: phone,
                    onChangeText: _onPhoneChange
                  }}
                  inputMargin={true}
              />
              <LabelInput
                  label="E-mail *"
                  inputProps={{
                    value: email,
                    onChange: _onEmailChange
                  }}
              />
            </div>
            <div className="row">
              <LabelTextArea
                  label="Mensagem *"
                  inputProps={{
                    value: message,
                    onChange: _onMessageChange
                  }}
              />
            </div>
            <span onClick={() => !loading && _mailSend()}
                  className={`row items-center content-center contact-send-btn ${emailSent ? 'contact-send-btn-sent' : ''}`}>
              {emailSent ? 'Enviado' : (loading ? 'Enviando...' : 'ENVIAR')}
            </span>
          </div>
          <img className="contact-right-bottom-bg" src={contact_right_bottom_bg} alt="error" />
        </div>
      </div>
  );
}

export default SectionContact;
