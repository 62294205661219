import React from 'react';
import './style/WhatWeOfferServiceItem.css';

const WhatWeOfferServiceItem = ({image, title, subTitle, text}) => (
    <div className="column what-we-offer-service-item">
      <div className="row">
        <div className="items-center content-center service-item-img-container">
          <img src={image} alt='error' />
        </div>
        <div className="column">
          <span className="service-item-text">{title}</span>
          <span className="service-item-text"><strong>{subTitle}</strong></span>
        </div>
      </div>
      <p className="what-we-offer-service-item-text">{text}</p>
    </div>
)

export default WhatWeOfferServiceItem;
