import React from 'react';
import './style/SectionServiceItem.css';
import arrow_right from '../../resource/arrow-right-wine.png';

const SectionServiceItem = ({title, subTitle, itemList = []}) => {
  const _renderItem = (text, idx) => (
      <div key={`page-our-services-item-text-${idx}`} className="row items-center page-our-services-item-text-container">
        <div className="items-center content-center page-our-services-item-arrow-container">
          <img src={arrow_right} className="page-our-services-item-arrow" alt="error" />
        </div>
        <p className="page-our-services-item-text-2">{text}</p>
      </div>
  )

  return (
    <div className="page-our-services-item">
      <div className="page-our-services-item-title page-our-services-item-title-high">
        <p>{title}</p>
        <p><strong>{subTitle}</strong></p>
      </div>
      <div className="page-our-services-item-title page-our-services-item-title-small">
        <p>{title} <strong>{subTitle}</strong></p>
      </div>
      <div className="flex-1 row flex-wrap page-our-services-item-container">
        {itemList.map(_renderItem)}
      </div>
    </div>
  );
}

export default SectionServiceItem;
