import React from 'react';
import './style/OtherService.css'
import ServiceItem from './ServiceItem';

const OtherService = ({image, title, subTitle, text}) => (
    <div className="page-our-services-other-service">
      <ServiceItem
          image={image}
          title={title}
          subTitle={subTitle}
      />
      <div className="page-our-services-other-service-divider" />
      <p className="flex-1 page-our-services-other-service-text">{text}</p>
    </div>
);

export default OtherService;
