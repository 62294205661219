import React from 'react';
import './style/SectionServiceItem.css';
import arrow_right from '../resource/arrow-right.png';
import {Link} from 'react-router-dom';

const SectionServiceItem = ({title, subTitle, text, image}) => (
    <div className="row our-service-item">
      <a className="items-center content-center our-services-icon-arrow-container" href={'/nossos-servicos'}>
        <img src={arrow_right} className="our-services-icon-arrow" alt="error" />
      </a>
      <div className="column our-service-item-text-container">
        <div className="column">
          <p className="our-service-item-text">
            {title}
            {subTitle && (<strong> {subTitle}</strong>)}
          </p>
          <div className="row items-center">
            <p className="our-service-item-text">
              <strong>{text}</strong>
            </p>
            {image && (<img src={image} className="our-service-item-img" alt="error" />)}
          </div>
        </div>
        <a className="our-service-item-link" href={'/nossos-servicos'}>
          Saiba Mais
        </a>
      </div>
    </div>
);

export default SectionServiceItem;
