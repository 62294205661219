import React from 'react';
import './style/ServiceItem.css';

const ServiceItem = ({image, title, subTitle}) => (
    <div className="row items-center">
      <div className="items-center content-center page-our-services-item-img-container">
        <img src={image} alt='error' />
      </div>
      <div className="column page-our-services-item-text">
        <p>{title}</p>
        <p><strong>{subTitle}</strong></p>
      </div>
    </div>
);

export default ServiceItem;
