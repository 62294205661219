import React from 'react';
import './style/ButtonWhatsapp.css';
import DefaultButton from './DefaultButton';
import whatsapp_icon from '../resource/whatsapp-icon.png';

const ButtonWhatsapp = ({text, style = {}, onClick}) => (
    <div className="row items-center btn-whatsapp-content" style={style}>
      <DefaultButton text={text} isContact={true} onClick={onClick} />
      <div className="row items-center">
        <img src={whatsapp_icon} className="whatsapp-icon" alt="error"/>
        <span className="whatsapp-text">
          (15) <strong>99182-7527</strong>
        </span>
      </div>
    </div>
);

export default ButtonWhatsapp;
