import React from 'react';
import './style/SectionWhatWeOffer.css';
import SectionLeft from './SectionLeft';
import what_we_offer_bottom_bg from '../resource/what-we-offer-bottom-bg.png';
import WhatWeOfferServiceItem from './WhatWeOfferServiceItem';
import assist_juridica from '../resource/assist-juridica-icon.png';
import consult_juridica from '../resource/consult-juridica-icon.png';
import aulas_palestras from '../resource/aulas-palestras-icon.png';

const SectionWhatWeOffer = () => (
    <div id="section-what-we-offer" className="row items-stretch content-between">
        <SectionLeft textSize={290} text="O que oferecemos" />
        <div className="flex-1 column items-stretch what-we-offer-content">
          <p className="what-we-offer-title">
            Oferecemos atendimento completo e multidisciplinar, capaz de abranger diversos problemas enfrentados<br/>
            <strong>por você.</strong>
          </p>
          <div className="row content-between flex-wrap">
            <WhatWeOfferServiceItem
                image={assist_juridica}
                title="Assistência"
                subTitle="Jurídica"
                text="Atuamos em diversas áreas do direito, oferecendo assistência consultiva e contenciosa."
            />
            <WhatWeOfferServiceItem
                image={consult_juridica}
                title="Consultoria"
                subTitle="Jurídica"
                text="Auxiliamos pessoas jurídicas e outros escritórios de advocacia a lidar com as questões envolvendo gênero e diversidade dentro e fora de seus ambientes profissionais."
            />
            <WhatWeOfferServiceItem
                image={aulas_palestras}
                title="Aulas e"
                subTitle="Palestras"
                text="Promovemos o direito das mulheres e da população LGBTQI+ através da conscientização e informação, dentro de empresas, universidades, etc."
            />
          </div>
        </div>
        <img src={what_we_offer_bottom_bg} className="what-we-offer-bottom-bg" alt="error" />
    </div>
);

export default SectionWhatWeOffer;
