import React from 'react';
import './style/SectionInitialDivider.css';
import ServiceItem from './ServiceItem';
import assist_juridica from '../resource/assist-juridica-icon.png';
import consult_juridica from '../resource/consult-juridica-icon.png';
import aulas_palestras from '../resource/aulas-palestras-icon.png';

const SectionInitialDivider = () => (
    <div id="section-initial-divider" className="row items-center content-between">
      <span className="items-center content-center initial-divider-content-square">
        Oferecemos
      </span>
      <div className="section-initial-divider-items">
        <ServiceItem image={assist_juridica} text="Assistência" subText="Jurídica" />
        <ServiceItem image={consult_juridica} text="Consultoria" subText="Jurídica"  />
        <ServiceItem image={aulas_palestras} text="Aulas e" subText="Palestras"  noBorder />
      </div>
    </div>
);

export default SectionInitialDivider;
