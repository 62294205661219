import React from 'react';
import './style/SectionAssistJurid.css';
import divider_bg from '../../resource/divider-bg.png';
import assist_juridica_icon from '../../resource/assist-juridica-icon.png';
import OtherService from './OtherService';

const SectionAssistJurid = () => (
    <div id="page-our-services-assist-jurid" className="column items-center content-center">
      <img style={{position: 'absolute', width: '100%', top: 0, left: 0, right: 0, zIndex: 2}} src={divider_bg} alt="error"/>
      <div className="column items-stretch">
        <OtherService
            image={assist_juridica_icon}
            title="Assistência"
            subTitle="Jurídica"
            text="Além de atuarmos em causas de menor complexidade, nosso escritório também é especializado em causas que reflitam algum tipo de desigualdade de gênero e que necessitem de um olhar mais sensível e engajado."
        />
        <div className="page-our-services-assist-jurid-info">
          <p className="page-our-services-assist-jurid-info-text">
            *Devido à complexidade das relações sociais, não é possível esgotar essas causas dentro das existentes áreas do Direito. Por isso, apresentamos de maneira exemplificativa, algumas das temáticas com as quais mais trabalhamos. Se o seu problema não está nas áreas abaixo listadas, <strong>entre em contato conosco.</strong>
          </p>
        </div>
      </div>
    </div>
);

export default SectionAssistJurid;
