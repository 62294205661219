import React from 'react';
import './style/LabelInput.css';
import './style/LabelTextarea.css';

const LabelTextArea = ({label, inputProps = {}}) => (
    <div className="flex-1 column items-stretch">
      <p className="input-label">{label}</p>
      <textarea {...inputProps} className="input-textarea" />
    </div>
);

export default LabelTextArea;
