import React from 'react';
import './style/CircleIcon.css'

const CircleIcon = ({
    image,
    style = {},
    isHover = false,
    size = 50,
    iconSize = 20,
    onClick,
  }) => (
    <div onClick={onClick} className={`circle-icon-container ${isHover ? 'circle-icon-container-hover' : ''}`}
        style={{width: size, height: size}}>
      <div className="flex-1 row items-center content-center circle-icon-content" style={style}>
        {image && (<img src={image} style={{width: iconSize}} alt="error" />)}
      </div>
    </div>
);

export default CircleIcon;
