import React from 'react';
import './style/SectionOurServices.css';
import SectionLeft from './SectionLeft';
import our_services_right_bg from '../resource/our-services-right-bg.png';
import SectionServiceItem from './SectionServiceItem';
import lgbtqi from '../resource/lgbtqi-icon.png';
import ButtonWhatsapp from './ButtonWhatsapp';
import divider_bg from '../resource/divider-bg.png';
import {openWhatsApp} from '../util/Action';

const SectionOurServices = () => (
    <div id="section-our-services" className="row items-stretch content-between">
      <img style={{position: 'absolute', width: '100%', top: 0, left: 0, right: 0, zIndex: 2}} src={divider_bg} alt="error"/>
      <SectionLeft textSize={260} text="Nossos Serviços" />
      <div className="flex-1 column content-between items-stretch section-our-services-content">
          <p className="section-our-services-title">
              <strong>Atuação completa e multidisciplinar,</strong> capaz de abranger diversos problemas.
          </p>
          <div className="flex-1 row flex-wrap section-our-services-items-container">
            <SectionServiceItem title="Direito" text="Civil" />
            <SectionServiceItem title="Direito da" text="Família e Sucessões" />
            <SectionServiceItem title="Direito do" text="Consumidor" />
            <SectionServiceItem title="Direito" text="Previdenciário" />
            <SectionServiceItem title="Direito" text="Penal" />
            <SectionServiceItem title="Direito da" text="Mulher" />
            <SectionServiceItem title="Direito" text="LGBTQI" image={lgbtqi} />
            <SectionServiceItem title="Direitos" subTitle="Sexuais" text="e Reprodutivos" />
            <SectionServiceItem title="Direito" text="Trabalhista" />
            <SectionServiceItem title="Consultaoria à" text="Empresas e Startups" />
          </div>
        <ButtonWhatsapp
            onClick={() => openWhatsApp('Olá vim pelo site. Tenho uma questão jurídica e gostaria de agendar uma consulta, qual seria a melhor data?')}
            text="Agende sua consulta"
            style={{alignSelf: 'center'}}
        />
      </div>
      <img src={our_services_right_bg} className="img-our-services-right-bg" alt="error" />
    </div>
);

export default SectionOurServices;
