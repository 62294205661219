import React from 'react';
import './style/Header.css';
import {Link} from 'react-router-dom';
import logo from '../resource/logo.png'
import {scrollTo} from '../util/Action';

const Header = () => (
  <header className="row items-stretch">
    <div className="header-center">
      <img src={logo} className="header-logo" alt={'error'}/>
      <div className="header-btn-container row items-center">
        <Link className="header-menu-item" to='/'>
          Início
        </Link>
        <div className="header-divider" />
        <div className="header-menu-item" data-scroll="section-who-we-are"
             onClick={scrollTo}>
          Quem Somos
        </div>
        <div className="header-divider" />
        <div className="header-menu-item" data-scroll="section-our-services"
             onClick={scrollTo}>
          Nossos Serviços
        </div>
        <div className="header-divider" />
        <div className="header-menu-item" data-scroll="section-contact"
             onClick={scrollTo}>
          Contato
        </div>
      </div>
    </div>
  </header>
);

export default Header;
