import React from 'react';
import './style/SectionServiceItems.css';
import SectionServiceItem from './SectionServiceItem';

const SectionServiceItems = () => (
    <div id="page-our-services-items" className="column items-stretch content-center">
      <SectionServiceItem
        title="Direito"
        subTitle="Civil"
        itemList={[
          'Ações de indenização por danos morais e materiais',
          'Bullying e ciberbullying, como ataques de ódio na internet',
          'Pornografia de vingança e divulgação indevida de imagens íntimas',
          'Demais demandandas cíveis',
        ]}
      />
      <SectionServiceItem
          title="Direito"
          subTitle="da Família"
          itemList={[
            'Divórcio, separação e partilha de bens',
            'Pensão alimentícia',
            'Guarda e Regime de convivência',
            'Alienação parental',
            'Mediação de conflitos',
            'Planejamento patrimonial: escolha do regime de bens, pacto antenupcial e planejamento sucessório',
            'Inventário',
          ]}
      />
      <SectionServiceItem
          title="Direito"
          subTitle="Sexuais e Reprodutivos"
          itemList={[
            'Violência obstétrica',
            'Direito à maternidade e planejamento familiar',
            'Aborto legal',
            'Direito à creches e escola para filhos',
            'Direitos da mãe universitária e trabalhadora',
          ]}
      />
      <SectionServiceItem
          title="Direito"
          subTitle="Trabalhista"
          itemList={[
            'Assédio sexual',
            'Assédio moral',
            'Igualdade salarial',
            'Discriminações com base no gênero',
            'Direitos da mulher gestante',
            'Demais direitos trabalhistas',
          ]}
      />
      <SectionServiceItem
          title="Direito"
          subTitle="Previdenciário"
          itemList={[
            'Pensão por morte',
            'Aposentadoria',
            'Aposentadoria de Pessoas transexuais',
            'Acidente de trabalho e doenças ocupacionais',
          ]}
      />
      <SectionServiceItem
          title="Direito"
          subTitle="LGBTQI+"
          itemList={[
            'Indenizações por discriminação e LGBT+fobia',
            'Reconhecimento e dissolução de uniões homoafetivas',
            'Retificação de nome social, gênero e registro civil',
            'Adoção',
          ]}
      />
      <SectionServiceItem
        title="Direito"
        subTitle="Penal"
        itemList={[
          'Violência doméstica e Lei Maria da Penha',
          'Violência sexual',
          'Crimes contra a honra (calúnia, injúria e difamação)',
          'Ameaças',
          'Perseguições',
          'Violência digital: pornografia de vingança, ataques de ódio na internet e outros',
        ]}
      />
    </div>
);

export default SectionServiceItems;
