import React from 'react';
import './style/CircleIconText.css';
import CircleIcon from './CircleIcon';

const CircleIconText = ({
                          title,
                          text,
                          image,
                          style = {},
                          size = 50,
                          iconSize = 20,
                          isHover = false,
                          onClick,
                        }) => (
    <div className="row items-center circle-icon-text-container">
      <CircleIcon
          onClick={onClick}
          isHover={isHover}
          image={image}
          style={style}
          size={size}
          iconSize={iconSize}
      />
      <div className="column circle-icon-text-content">
        <p className="circle-icon-title">{title}</p>
        <p className="circle-icon-text">{text}</p>
      </div>
    </div>
);

export default CircleIconText;
