import React, {PureComponent} from 'react';
import './style/SectionBanner.css';
import left from '../resource/icon-arrow-left.svg';
import right from '../resource/icon-arrow-right.svg';
import lgbtqi from '../resource/lgbtqi-icon.png';
import DefaultButton from './DefaultButton';
import banner_left_bg from '../resource/banner-left-bg.png';
import banner_right_bg from '../resource/banner-right-bg.png';

class SectionBanner extends PureComponent {

  state = {
    activeBanner: 0,
    transitionSquareList: null,
    bannerContentList: null,
  }

  componentDidMount() {
    const transitionSquareList = document.querySelectorAll(".transition-square");
    const bannerContentList = document.querySelectorAll(".banner-content");

    this.setState({
      transitionSquareList,
      bannerContentList
    }, this._startInterval);
  }

  _startInterval = () => {
    const {activeBanner, transitionSquareList, bannerContentList} = this.state;
    transitionSquareList[activeBanner].classList.add("active");
    bannerContentList[activeBanner].classList.add("active");

    setInterval(() => {
      const {activeBanner, transitionSquareList, bannerContentList} = this.state;

      const maxSize = transitionSquareList.length;

      let newActiveBanner = activeBanner + 1;
      newActiveBanner = newActiveBanner === maxSize ? 0 : newActiveBanner;

      this.setState(
          {activeBanner: newActiveBanner},
          () => this._toggleActiveSquare(
              activeBanner,
              newActiveBanner,
              transitionSquareList,
              bannerContentList
          )
      );
    }, 5000);
  }

  _toggleActiveSquare = (activeBanner, newActiveBanner, transitionSquareList, bannerContentList) => {
    transitionSquareList[activeBanner].classList.remove("active");
    bannerContentList[activeBanner].classList.remove("active");
    setTimeout(() => {
      transitionSquareList[newActiveBanner].classList.add("active");
      bannerContentList[newActiveBanner].classList.add("active");
    }, 800);
  }

  _firstBanner = () => (
      <div className="column items-center banner-content">
        <div className="column items-center">
          <span className="items-center content-center banner-content-square">
            Somos
          </span>
          <span className="banner-text">
            Especialistas <strong>em resolver o seu problema!</strong>
          </span>
          <DefaultButton text="Saiba mais" />
        </div>
      </div>
  );

  _secondBanner = () => (
      <div className="column items-center banner-content">
        <div className="column items-center">
        <span className="items-center content-center banner-content-square">
          Atuamos com
        </span>
          <span className="banner-text">
          Direito das <br/><strong>Mulheres e Pessoas LGBTQI</strong>
          <img src={lgbtqi} className="lgbtqi-icon" alt={'error'}/>
        </span>
          <DefaultButton text="Saiba mais" />
        </div>
      </div>
  );

  render() {
    return (
      <div id="section-banner" className="row items-stretch">
        <img src={banner_left_bg} className="banner-left-bg" alt="error" />
        <div className="flex-1 row content-between items-stretch section-banner-content">
          {/*<img src={left} className="icon-arrow" alt={'error'}/>*/}
          <div id="banner-container">
            {this._firstBanner()}
            {this._secondBanner()}
          </div>
          {/*<img src={right} className="icon-arrow" alt={'error'}/>*/}
        </div>
        <div
            className="transition-square-container row items-center content-center">
          <div className="transition-square" />
          <div className="transition-square" />
        </div>
        <img src={banner_right_bg} className="banner-right-bg" alt="error" />
      </div>
    );
  }
}

export default SectionBanner;
