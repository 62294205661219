export const scrollTo = ({currentTarget}) => {
  const sectionId = currentTarget.getAttribute('data-scroll');
  const element = document.getElementById(sectionId);

  if (!element) {
    window.location = `/#${sectionId}`;
  } else {
    window.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
    });
  }
};

export const openWhatsApp = (text) => {
  window.open(`https://wa.me/5515991827527?text=${text}`);
};

export const openFacebook = () => {
  window.open(`https://www.facebook.com/thalitalucarelli/`);
};

export const openInstagram = () => {
  window.open(`https://www.instagram.com/thalitalucarelli/`);
};

export const openPhone = () => {
  const text = '15991827527';
  copyText(text);

  const linkEl = document.createElement('a');
  linkEl.href = `tel:+55${text}`;
  linkEl.target = '_self';
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};

export const openMail = () => {
  const linkEl = document.createElement('a');
  linkEl.href = `mailto:tlucarelli.adv@gmail.com`;
  linkEl.target = '_self';
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};

export const copyText = (text) => {
  const el = document.createElement('input');
  el.value = text;

  document.body.appendChild(el);
  el.focus();
  el.select();
  el.setSelectionRange(0, 99999); /*For mobile devices*/

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(el);
};
