import React from 'react';
import dot_img from '../resource/dot-img.png';

const ItemValue = ({text}) => (
    <div className="row items-center item-value">
      <img src={dot_img} className="dot_img" alt="error"/>
      <p className="section-who-we-are-text-2">{text};</p>
    </div>
);

export default ItemValue;
