import axios from '../config/axios';

export const sendMail = (data) => axios({
  method: 'POST',
  url: '/sendEmail',
  data,
}).then(response => {
  console.warn('response: ', response);
});

